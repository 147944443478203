<script setup>

</script>

<template>
  <div class="gapQuote">
    <slot></slot>
  </div>
</template>

<style lang="scss">
  .gapQuote{
    color: #fcfcfc;
    width: 90%;
    max-width: 1000px;
    margin: 20px auto;
    &:after{
      content: '';
      display: block;
      width: 90%;
      height: 1px;
      background: #e58237;
      margin: 10px 0;
      opacity: 0.2;
    }
    h3{
      font-size: 1.6rem;
      margin-bottom: 10px;
      line-height: 1.2;
      color: #e58237;
    }
    span{
      display: block;
      font-size: 1.2rem;
      line-height: 1.2;
      text-align: left;
    }
    a{
      font-weight: bold;
      text-align: left;
      color: #fcfcfc;
      display: block;
      margin: 10px 0 0 0;
    }
  }
</style>
