<script setup>
import { ref } from 'vue';

// Zustandsvariable, die die Sichtbarkeit des iFrames steuert
const isIframeVisible = ref(false);

const toggleIframeVisibility = () => {
  isIframeVisible.value = !isIframeVisible.value;
}
</script>

<template>
  <div class="youtubeConsent"
       v-if="!isIframeVisible"
       @click="toggleIframeVisibility"
  >
    <div class="play">
    </div>
    <img src="../assets/video.jpg" alt=""
      class="youtubePlaceholder"
    />
  </div>
  <div class="youtubeConsentH1"
       v-if="!isIframeVisible"
       @click="toggleIframeVisibility"
  >
    Ich bin damit einverstanden, dass mit dem Aktivieren des YouTube-Videos Daten von meinem Gerät an YouTube übertragen werden. Diese Einwilligung umfasst auch die Übertragung meiner Daten in die USA. Mir ist bewusst, dass für Daten, die in die USA übertragen werden, unter Umständen nicht das gleiche Schutzniveau besteht wie in der EU. Weitere Informationen zur Datenverarbeitung finde ich in der <a href="https://policies.google.com/privacy?hl=de" target="_blank">Datenschutzerklärung von YouTube</a>.
  </div>
  <div class="responsive-iframe-container" v-if="isIframeVisible">
    <iframe
        class="video"
        src="https://www.youtube-nocookie.com/embed/hI_R3chdsgk?si=1U5iuVYWFbQarh9-&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
  </div>
</template>

<style scoped lang="scss">
.responsive-iframe-container {
  position: relative;
  padding-bottom: 56.25%; // 16:9 Aspect Ratio
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 95vh;
  }
  .video {
    aspect-ratio: 16 / 9;
    width: 100%; // Stellt sicher, dass das iFrame die volle Breite einnimmt
  }
}
  .youtubeConsentH1{
    position: relative;
    font-size: 0.8rem;
    padding: 10px;
    @media (min-width: 699px) {
      font-size: 1rem;
      position: absolute;
      bottom: 100px;
      left: 50%;
      transform: translateX(-50%);
      padding: 0;
    }
    max-width: 1000px;
    text-align: center;
    margin: 0 auto;
    color: #fcfcfc;
    a{
      color: #e58237;
    }
  }


.play {
  background: red;
  border-radius: 50% / 10%;
  color: #FFFFFF;
  font-size: 2em; /* change this to change size */
  height: 3em;
  margin: 20px auto;
  padding: 0;
  text-align: center;
  text-indent: 0.1em;
  transition: all 150ms ease-out;
  width: 4em;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%)
}

.play:hover {
  background: darkorange;
}

.play::before {
  background: inherit;
  border-radius: 5% / 50%;
  bottom: 9%;
  content: "";
  left: -5%;
  position: absolute;
  right: -5%;
  top: 9%;
}

.play::after {
  border-style: solid;
  border-width: 1em 0 1em 1.732em;
  border-color: transparent transparent transparent rgba(255, 255, 255, 0.75);
  content: ' ';
  font-size: 0.75em;
  height: 0;
  margin: -1em 0 0 -0.75em;
  top: 50%;
  position: absolute;
  width: 0;
}
.youtubeConsent{
  position: relative;
  cursor: pointer;
  &:hover{
    .play{
      background: darkorange;
    }
  }
}
.youtubePlaceholder{
  object-fit: cover;
  @media (min-width: 700px) {
    height: 80vh;
  }
  @media (max-width: 699px) {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
