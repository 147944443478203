<template>
  <div ref="googleMapsDiv" class="googleMapsDiv">
    <div class="googleMapsDiv__consent" >
      <h2 @click="handleClick">
        Klicke hier, um Google Maps zu laden!
      </h2>
      <span @click.stop>
        Ich bin damit einverstanden, dass mit dem Aktivieren von Google Maps Daten von meinem Gerät an Google übertragen werden und dass Google Maps auf meinem Gerät Cookies setzen kann.
        Diese Einwilligung umfasst die Übertragung meiner Daten in die USA. Mir ist bewusst, dass für Daten, die in die USA übertragen werden, unter Umständen nicht das gleiche Schutzniveau besteht wie in der EU. Weitere Informationen zur Datenverarbeitung finde ich in der <a href="https://policies.google.com/privacy?hl=de" target="_blank">Datenschutzerklärung von Google</a>.
      </span>
    </div>
  </div>
</template>

<script setup>
/* global google */
import { nextTick, ref, onMounted } from 'vue';
import { Loader } from '@googlemaps/js-api-loader';
const googleMapsDiv = ref();

const handleClick = () => {
  // Prüfen, ob die Karte bereits geladen wurde
  const mapLoaded3 = localStorage.getItem('mapLoaded3');
  if (mapLoaded3 !== 'true') {
    showMap();
  }
};

onMounted(() => {
  const mapLoaded3 = localStorage.getItem('mapLoaded3');
  if (mapLoaded3 === 'true') {
    showMap();
  }
});
const showMap = () => {
  const loader = new Loader({
    apiKey: 'AIzaSyAxeye7PySq44gxZ0W5KaJL6nixhAoMtbs',
    version: 'weekly',
  });
  nextTick(() => {
    loader.load().then(() => {
      if (googleMapsDiv.value) {
        const map = new google.maps.Map(googleMapsDiv.value, {
          center: { lat: 51.41016387939453, lng: 6.781673431396484 },
          zoom: 18,
          mapTypeId: google.maps.MapTypeId.SATELLITE
        });
        new google.maps.Marker({
          position: { lat: 51.41016387939453, lng: 6.781673431396484 },
          map: map,
          title: 'GAP AM SEE!',
        });
      }
    });
  })
  localStorage.setItem('mapLoaded3', 'true');
}
</script>
<style>
.googleMapsDiv {
  height: 900px; /* Höhe der Karte */
  width: 100%; /* Breite der Karte */
  background: url(../assets/googleMaps.jpg);
  position: relative;
  .googleMapsDiv__consent{
    max-width: 1000px;
    text-align: center;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    h2{
      color: #e58237;
      font-size: 2rem;
      line-height: 2;
      cursor: pointer;
    }
    span{
      font-size: 1rem;
      margin-top: 20px;
      line-height: 1.2;
      display: block;
      color: #fcfcfc;
      text-align: left;
      pointer-events: none;
      a{
        pointer-events: all;
        color: #e58237;
      }
    }
  }
}
</style>
