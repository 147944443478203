<script setup>
import {defineProps, onMounted, onUnmounted, ref, watch} from 'vue';

const props = defineProps({
  isVisible: Boolean
});

const scale = ref(2);
const imageSlider = ref(null);
let lastScrollPosition = 0;

const direction = ref('down');
const checkVisibility = () => {
  const isDesktop = window.innerWidth > 1024;
  if (!imageSlider.value) return;
  if(!isDesktop){
    scale.value = 1;
    return;
  }
  const rect = imageSlider.value.getBoundingClientRect();
  const currentScrollPosition = window.scrollY;

  // Prüft, ob wir nach unten oder oben scrollen
  const scrollingDown = currentScrollPosition > lastScrollPosition;
  lastScrollPosition = currentScrollPosition;

  console.info(rect.top);
  console.info(window.innerHeight);

  if(rect.top < window.innerHeight / .53 && scrollingDown) {
    console.info('kleiner');
    direction.value = 'down';
    // Verringert scale.value beim Scrollen nach unten, aber nicht unter 1
    scale.value = Math.max(1, scale.value - 0.008);
  } else if (rect.top >= window.innerHeight / 1.5 && !scrollingDown) {
    // Erhöht scale.value beim Scrollen nach oben, bis es wieder 2 erreicht
    scale.value = Math.min(2, scale.value + 0.007);
    direction.value = 'up';
  }
  console.info('.-----');
};

const manageScrollEvent = (add) => {
  const isDesktop = window.innerWidth > 1024;
  if(!isDesktop){
    scale.value = 1;
    return;
  }
  if (add) {
    window.addEventListener('scroll', checkVisibility);
  } else {
    if(direction.value === 'up'){
      scale.value = 2;
    }
    window.removeEventListener('scroll', checkVisibility);
  }
};

watch(() => props.isVisible, (newVal) => {
  manageScrollEvent(newVal);
}, { immediate: true });

onMounted(() => {
  if (props.isVisible) {
    manageScrollEvent(true);
  }
});

onUnmounted(() => {
  manageScrollEvent(false);
});
</script>

<template>
  <div ref="imageSlider" class="image-slider">
    <div class="image-slider__inner"
    :style="`transform: scale(${scale})`"
    >
      <div class="image-slider__col">
        <img src="../assets/gapamsee_1.jpg"/>
        <img src="../assets/gapamsee_4.jpg"/>
      </div>
      <div class="image-slider__col image-slider__center">
        <img src="../assets/gapamsee_3.jpg" class="image-slider__main"/>
      </div>
      <div class="image-slider__col">
        <img src="../assets/gapamsee_7.jpg"/>
        <img src="../assets/gapamsee_8.jpg"/>
      </div>
      <div class="image-slider__col-full">
        <img src="../assets/gapamsee_9.jpg"/>
        <img src="../assets/gapamsee_10.jpg"/>
        <img src="../assets/gapamsee_11.jpg"/>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.image-slider{
  @media (min-width: 700px) {
  height: 100vh;
  overflow: hidden;
  .image-slider__inner{
    transform: scale(2.2);
    position: relative;
    text-align: center;

    display: flex;
    flex-wrap: wrap;

    .image-slider__col-full{
      flex-basis: 100%;
      img {
        width: 33vw;
      }
    }

    .image-slider__col {
      flex: 2 2 3.333%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &.image-slider__center{
        display: flex;
        justify-content: center;
        img:not(.image-slider__main){
          width: 50%;
          max-width: 50%;
          height: auto;
          margin: 0 5px;
        }
      }
    }
    img{
      display: inline-block;
      object-fit: contain;
      &:not(.image-slider__main){
        width: 20vw;
        margin: 10px;
      }
      &.image-slider__main{
        width: 50vw;
      }
    }
  }
  }
  @media (max-width: 699px) {
    .image-slider__col{

    }
    img{
      width: 95vw;
    }
  }
}
</style>
