<template>
  <div
      ref="scrollContainer"
  >
    <new-row
        @slowly="getSlowly"
    >
      <div class="text-container gapLogo" ref="textContainer">
        <div class="image-text-banner">
          <img src="./assets/logo.png"/>
        </div>
      </div>
      <div class="images-container" ref="imagesContainer">
        <img src="./assets/gapamsee_2.jpg" alt="GAP AM SEE, DUISBURG"/>
      </div>
    </new-row>
    <new-row
        @slowly="getSlowly"
    >
      <div class="text-container text-container__center">
        <div class="image-text-banner">
          <h2>GAP steht für Gelato - Aperitifs - Pizzella</h2>
        </div>
        <div class="text-floating">
          <p>
            <img
                class="text-floating__image"
                src="./assets/eisbecher.jpg"/>
            Und genau das ist, was ihr bei uns -in wunderschöner Atmosphäre direkt am Bertasee- bekommt: köstliche Eiskreationen, leckere Aperitifs und - NEU - Pizzella, ein deftiger Snack für zwischendurch. Außerdem gibts natürlich auch Kuchen, Shakes, Kaltgetränke oder ein Käffchen.<br />
            Serviert mit einem Lächeln und in stilvollem Ambiente. Folgt uns auch bei <a href="https://www.facebook.com/p/GAPamSee-100093218911572/" target="_blank">Facebook</a> und <a href="https://www.instagram.com/gap.am.see" target="_blank">Instagram</a> um keine Neuigkeiten und Aktionen zu verpassen.
          </p>
        </div>
      </div>
    </new-row>
    <new-row :fullHeight="true"
             :slowly="true"
             @slowly="getSlowly"
    >
      <template v-slot:default="{ isVisible }">
        <div>
          <image-slider :isVisible="isVisible"/>
        </div>
      </template>
    </new-row>
    <new-row
        @slowly="getSlowly"
    >
      <h2>Entdecke unser Gelato, Aperitifs und Pizzella</h2>
      <div class="marginTop">
        <p>
          Schau mal in unsere Speisekarte rein und entdecke, was wir zu bieten haben! Von kreativen Spaghetti-Eis-Variationen bis hin zu unseren "Cold Specials" wie Eisschokolade und Eiscafés – direkt am Ufer des Bertasees. Hier gibt es etwas für jeden Geschmack, egal ob du ein großer Schleckermäulchen oder ein kleiner Naschkatze bist.
        </p>
        <a href="Speisekarte_gap.pdf" target="_blank">
          <img src="./assets/speisekarte.jpg" alt="GAP Duisburg Speisekarte"/>
        </a>
      </div>
    </new-row>
    <new-row>
      <h2>Was ist das Besondere?</h2>
      <youtube-player />
    </new-row>
    <new-row>
      <quote-row>
        <h2>Andere über uns:</h2>
        <h3>GAP am See: Lohnt sich die neue Eisdiele in Duisburg?</h3>
        <span>
          Atmosphäre: Was die Lage der Eisdiele angeht, haben die Betreiber nicht zu viel versprochen. Die Lage mit direktem Blick auf den Bertasee könnte kaum schöner sein. Ruhig, aber trotzdem sowohl zu Fuß als auch mit dem Fahrrad oder Auto gut zu erreichen. Im Vergleich zum typischen Eiscafé an der Straße oder in der Innenstadt ist das ein Pluspunkt.
        </span>
        <span>
          GAP am See überzeugt in Duisburg mit seiner besonderen Lage
        </span>
        <span>
          Auch wenn das Eis bei GAP am See nicht gerade günstig ist, lohnt sich ein Besuch. Denn die Eisdiele hebt sich nicht nur mit der großen Auswahl, sondern vor allem mit der schönen Lage von anderen Eisdielen ab. Das Auge isst hier mit.
        </span>
        <a href="https://www.nrz.de/staedte/duisburg/article238925681/gap-am-see-lohnt-sich-die-neue-eisdiele-in-duisburg.html" target="_blank">nrz.de</a>
      </quote-row>
      <quote-row>
        <h3>Neue Eisdiele direkt am See: Perfekte Aussicht und mehr</h3>
        <span>
          Es ist ein wahrhaft traumhafter Standort: Direkt am Bertasee, mit Blick auf das blaue Wasser, können Spaziergänger und Ausflügler seit neustem eine Pause im „GAPamSee“ einlegen.
        </span>
        <a href="https://www.waz.de/staedte/duisburg/article238755807/neue-eisdiele-direkt-am-see-perfekte-aussicht-und-mehr.html" target="_blank">waz.de</a>
      </quote-row>
      <quote-row>
        <h3>Eine kulinarische Genusskombi für alle Eis-, Aperitif- und Pizzaliebhaber</h3>
        <span>
          Ein Besuch im GAP ist wie der Besuch einer Oase, in der die Auszeit vom Alltag so richtig zelebriert werden kann. Mit Blick auf den herrlichen Bertasee können sich alle Gäste in dem stilvollen Ambiente ein gemütliches Plätzchen suchen und ganz nach Laune ihr Menü zusammenstellen lassen.
        </span>
        <a href="https://kulinarische-schnitzeljagd.de/gapamsee-in-duisburg" target="_blank">kulinarische-schnitzeljagd.de</a>
      </quote-row>
    </new-row>
    <new-row>
      <h2>Hier findest du uns:</h2>
      <div class="text-default">
        <p>📍 Bertaallee 7, 47055 Duisburg</p>
        <p>Direkt neben der Schauinsland-Reisen-Arena, des MSV Duisburg</p>
      </div>
      <google-maps />
    </new-row>
    <new-row :undefinedHeight="true">
      <div class="imprint">
        <h2>Impressum</h2>
        1. Angaben gemäß § 5 des Telemediengesetzes<br />

        Name des Anbieters:<br />

        MEZZOMAR SEEHAUS Duisburg-Wedau<br />
        MEZZO-Wedau GmbH<br />
        Bertaallee 7<br />
        47055 Duisburg<br />

        <hr />

        E-Mail:   info@mezzomar.de<br />
        Telefon: 02 81-206 594 50<br />
        Telefax:  02 81-206 594 52<br />
        Internetseite: www.mezzomar.de<br />
        <hr />
        <privacy />
      </div>
    </new-row>
  </div>
</template>

<script setup>

import quoteRow from "@/components/quoteRow.vue";

const slowly = ref(false);

const scrollContainer = ref(null);

console.warn('scrollContainer.value');
console.warn(scrollContainer.value);

const getSlowly = (bol) => {
  console.warn(bol);
  slowly.value = bol;
}

/*
const handleScroll2 = (event) => {
  const scrollSpeed = 2;
  if (slowly.value && scrollContainer.value) {
    event.preventDefault();
    const deltaY = event.deltaY * scrollSpeed;
    scrollContainer.value.scrollBy({ top: deltaY, behavior: 'smooth' });
  }
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll2);
});

 */


//import LocomotiveScroll from 'locomotive-scroll';
import NewRow from "./components/newRow.vue";
import { onMounted, onUnmounted, ref } from 'vue';
import ImageSlider from "@/components/imageSlider.vue";
import YoutubePlayer from "@/components/YoutubePlayer.vue";
import GoogleMaps from "@/components/googleMaps.vue";
import Privacy from "@/components/privacy.vue";

const textContainer = ref(null);
const imagesContainer = ref(null);

const handleScroll = () => {
  const isDesktop = window.innerWidth > 1024;
  if(isDesktop){
    const scrollPosition = window.pageYOffset;
    textContainer.value.style.transform = `translate3d(-50%, ${scrollPosition * 0.5}px, 0)`;
    imagesContainer.value.style.transform = `translate3d(0, ${scrollPosition * 0.3}px, 0)`;
  }
};

onMounted(() => {
  /*
  new LocomotiveScroll({
    el: scrollContainer.value,
    smooth: true,
    multiplier: 1
  });

   */
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #262625;
}

*, *::after, *::before{
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  background: #262625;
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  scroll-behavior: smooth;
}


@font-face {
  font-family: 'Caviar Dreams';
  src: url('./assets/fonts/CaviarDreams-Bold.woff2') format('woff2'),
  url('./assets/fonts/CaviarDreams-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Caviar Dreams';
  src: url('./assets/fonts/CaviarDreams-Italic.woff2') format('woff2'),
  url('./assets/fonts/CaviarDreams-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Caviar Dreams';
  src: url('./assets/fonts/CaviarDreams-BoldItalic.woff2') format('woff2'),
  url('./assets/fonts/CaviarDreams-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Caviar Dreams';
  src: url('./assets/fonts/CaviarDreams.woff2') format('woff2'),
  url('./assets/fonts/CaviarDreams.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hunting Star';
  src: url('./assets/fonts/HuntingStarRegular.woff2') format('woff2'),
  url('./assets/fonts/HuntingStarRegular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.gapLogo{
  @media (max-width: 699px) {
    position: absolute;
    z-index: 999;
    top: 50%;
  }
}

h2{
  text-transform: none;
  color: #e58237;
  margin: 2rem 0;
  font-size: 1.54rem;
  line-height: 1.6rem;
  @media (min-width: 700px) {
    font-size: 4rem;
    line-height: .8em;
  }
  font-family: "Caviar Dreams";
}

*{
  font-family: "Caviar Dreams";
}
</style>
