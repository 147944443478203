<template>
  <div class="privacy-policy">
    <h1>Datenschutzerklärung</h1>

    <h4>1. Allgemeines</h4>
    <p>
      Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zwecke der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“) innerhalb unserer Webseite auf. Die Datenschutzerklärung gilt unabhängig von den verwendeten Domains, Systemen, Plattformen und Geräten (z.B. Desktop oder Mobile), auf denen die Webseite ausgeführt wird.
    </p>
    <p><strong>Verantwortlicher Anbieter:</strong><br>
      MEZZOMAR SEEHAUS Duisburg-Wedau<br>
      MEZZO-Wedau GmbH<br>
      Bertaallee 7<br>
      47055 Duisburg<br>
      Deutschland</p>
    <p><strong>Datenschutzbeauftragte:</strong><br>
      Paolo Tornetta & Calogero Nicolosi<br>
      E-Mail: <a href="mailto:datenschutz@mezzomar-wedau.de">datenschutz@mezzomar-wedau.de</a></p>

    <h4>2. Grundlegende Informationen zur Datenverarbeitung</h4>
    <p>
      Wir verarbeiten personenbezogene Daten der Webseitenbesucher nur in Übereinstimmung mit den einschlägigen Datenschutzvorschriften gemäß der DSGVO. Das bedeutet, dass die Daten der Nutzer nur bei Vorliegen einer gesetzlichen Erlaubnis, insbesondere wenn die Daten zur Bereitstellung unserer vertraglichen Leistungen sowie Online-Services notwendig, bzw. gesetzlich vorgeschrieben sind oder bei Vorliegen Ihrer Einwilligung verarbeitet werden.
    </p>

    <h4>3. Verarbeitung von Daten</h4>
    <h3>a. Beim Laden der Webseite</h3>
    <p>Beim Aufrufen unserer Webseite werden zunächst keine externen Bibliotheken geladen und keine Cookies auf Ihrem Gerät gespeichert. Unsere Webseite kann somit grundlegend genutzt werden, ohne dass personenbezogene Daten verarbeitet werden.</p>
    <h3>b. Einbindung von YouTube und Google Maps</h3>
    <ul>
      <li><strong>YouTube Videos:</strong> Nach Ihrer Zustimmung kann ein YouTube-Video eingebettet werden. Die Zustimmung zur Datenverarbeitung durch YouTube wird gesondert eingeholt und im Local Storage des Geräts gespeichert, bevor das Video geladen wird.</li>
      <li><strong>Google Maps:</strong> Ebenfalls nach Ihrer Zustimmung kann eine Karte von Google Maps geladen werden. Auch hier erfolgt die Speicherung Ihrer Zustimmung im Local Storage Ihres Geräts, bevor jegliche Daten an Google übermittelt werden.</li>
    </ul>
    <h3>c. Local Storage</h3>
    <p>Die Verwendung des Local Storages dient dazu, die von Ihnen erteilte Zustimmung zur Datenverarbeitung zu speichern. Diese Daten verbleiben auf Ihrem Gerät und werden nicht an uns oder Dritte weitergegeben.</p>

    <h4>4. Ihre Rechte als Betroffener</h4>
    <p>Sie haben das Recht, Auskunft über die von uns verarbeiteten personenbezogenen Daten zu verlangen. Zusätzlich haben Sie das Recht auf Berichtigung falscher Daten, Einschränkung der Verarbeitung, Löschung Ihrer personenbezogenen Daten, Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten und das Recht auf Datenübertragbarkeit (Artikel 15 bis 20 DSGVO). Ebenso haben Sie das Recht, eine erteilte Einwilligung zur Verarbeitung Ihrer personenbezogenen Daten jederzeit zu widerrufen.</p>

    <h4>5. Beschwerderecht bei der Aufsichtsbehörde</h4>
    <p>Sie haben gemäß Artikel 77 DSGVO das Recht, sich bei einer Aufsichtsbehörde zu beschweren, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten nicht rechtmäßig erfolgt.</p>

    <h4>6. Schlussbestimmungen</h4>
    <p>Wir behalten uns das Recht vor, diese Datenschutzerklärung jederzeit mit Wirkung für die Zukunft zu ändern. Die jeweils aktuelle Version ist direkt auf unserer Webseite verfügbar. Bitte informieren Sie sich regelmäßig über die geltende Datenschutzerklärung.</p>

    <p>Diese Datenschutzerklärung ist auf dem Stand vom April 2024. Wenn Sie Fragen zu dieser Datenschutzerklärung haben, können Sie unsere Datenschutzbeauftragten gerne kontaktieren.</p>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>
.privacy-policy {
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
  line-height: 1.5rem;
  color: #fcfcfc;
  padding: 20px 0;
  *{
    color: #fcfcfc;
  }
}
.privacy-policy h3, .privacy-policy h4 {
  color: #e58237 !important;
  font-weight: bold;
  margin-top: 20px;
}
.privacy-policy a {
  color: #e58237 !important;
}
</style>
