<script setup>
import { onMounted, onUnmounted, ref, defineProps, defineEmits } from 'vue';

const props = defineProps({
  fullHeight: {
    type: Boolean,
    default: false,
  },
  undefinedHeight: {
    type: Boolean,
    default: false,
  },
  slowly: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['slowly']);


const element = ref(null);
const isVisible = ref(false);

onMounted(() => {
  const observer = new IntersectionObserver(
      ([entry]) => {
        isVisible.value = entry.isIntersecting;
        if(props.slowly){
          emit('slowly',true)
        }else{
          emit('slowly',false)
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      }
  );

  if (element.value) {
    observer.observe(element.value);
  }

  onUnmounted(() => {
    if (element.value) {
      observer.unobserve(element.value);
    }
  });
});
</script>


<template>
  <div class="newRow"
       :class="{
    'newRow__fullheight': props.fullHeight,
       'newRow__undefinedHeight': props.undefinedHeight }"
       ref="element"
  >
    <slot :isVisible="isVisible"></slot>
  </div>
</template>

<style lang="scss">
.newRow{
  @media (min-width: 700px) {
    min-height: 90vh;
    height: 90vh;
    overflow: hidden;
  }
  @media (max-width: 699px) {
    padding-bottom: 20px;
  }
  &.newRow__fullheight{
    height: unset;
    @media (min-width: 700px) {
      height: 100vh;
    }
  }
  &.newRow__undefinedHeight{
    height: unset;
  }

  h2{
    width: 80%;
    margin: 10px auto;
    padding-bottom: 20px;
  }
  width: 100vw;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  opacity: 1;
  pointer-events: all;
  position: relative;
  background: #262625;
  .text-container {
    @media (min-width: 700px) {
      position: absolute;
      top: 50%;
      color: #fcfcfc;
      left: 50%;
      transform: translate3d(-50%, 0px, 0px);
      &.text-container__center {
        transform: translate3d(-50%, -50%, 0px);
      }
    }
    width: 100%;
    z-index: 3;
    margin: 0 auto;
    .text-floating{
      margin-left: auto;
      margin-right: auto;
      width: 66.666%;
      max-width: 720px;
      margin-top: 3rem;
      text-align: left;
      color: #fcfcfc;
      font-size: 1.2rem;
      line-height: 1.5;
      a{
        color: #e58237;
        font-weight: bold;
      }
      font-family: "Caviar Dreams";
      .text-floating__image{
        margin-bottom: 25px;
        @media (min-width: 700px) {
          float: left;
          margin-right: 25px;
          margin-left: -200px;
          margin-bottom: 0;
          display: inline-block;
        }
        width: 100%;
        max-width: 400px;
        vertical-align: middle;
      }
      p{
        display: inline-block;
        vertical-align: middle;
      }
    }
    .image-text-banner {
      width: 80%;
      @media (min-width: 700px) {
        max-width: 1400px;
      }
      img{
        width: 100%;
      }
      margin: 0 auto;
      h1{
        text-transform: none;
        color: #e58237;
        line-height: .8em;
        margin-bottom: 0.1em;
        font-size: 7.8vw;
        font-family: "Caviar Dreams";
      }
      h2{
        line-height: .9em;
        font-weight: 400;
        margin: 0 0 0.2em 0;
        position: relative;
        color: #e58237;
        text-transform: uppercase;
        letter-spacing: -.01em;
        font-size: 1.5rem;
        line-height: 1.5;
        @media (min-width: 700px) {
          font-size: 2rem;
        }
        font-family: "Caviar Dreams";
      }
    }
  }
  .images-container{
    @media (min-width: 700px) {
      z-index: 2;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: 100%;
      overflow: hidden;
    }
    img{
      @media (min-width: 700px) {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        object-fit: contain;
      }
      object-fit: cover;
      width: 100vw;
      height: 120vh;
      max-width: 100vw;
      max-height: 120vh;
      will-change: transform;
    }
  }
}
.imprint{
  max-width: 1000px;
  margin: 0 auto;
  text-align: left;
  line-height: 1.5rem;
  color: #fcfcfc;
  padding: 20px;
}
.marginTop{
  @media (max-width: 700px) {
    text-align: left;
    width: 80%;
  }
  margin: 20px auto;
  max-width: 1000px;
  line-height: 1.5rem;
  color: #fcfcfc;
  img{
    margin: 20px 0;
    @media (min-width: 700px) {
      margin: 20px;
    }
    max-width: 100%;
    width: 100%;
  }
}
.text-default{
  color: #ffffff;
  font-weight: bold;
  margin: 20px 0;
  line-height: 1.5;
}
</style>

